import React from "react"

import implantat from "../img/Implantat.png"

export default function Implantologie() {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-2 m-5 md:m-10 justify-between">
    <div className="md:w-full card ">
      
      <div className="mt-5 md:mt-8 flex flex-col gap-3">
     
       
       
      
      </div>
      <p className="mt- text-custom-gray text-base md:text-base font-semibold md:font-bold">
      IMPLANTOLOGIE - HIGH END DER ZAHNMEDIZIN
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Möchten Sie mit oder neben Ihren Zähnen schlafen?
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      3D-navigierte Implantologie - der EINZIG SICHERE Weg durch Erschließung digitaler Potentiale
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Sorgfalt in Diagnostik und Planung gehen jedem implantologischen Erfolg zwingend voraus. <br/><br/>Moderne Röntgendiagnostik (Dentale Volumentomographie mit 20% weniger Strahlenbelastung als ein CT) ermöglicht die 3-dimensionale Darstellung des Implantatlagers, lässt anatomische Besonderheiten und Knochendefizite erkennen. <br/><br/>DVT-gewonnene diagnostische Daten werden mit einer speziellen Planungssoftware bearbeitet, um die Implantatposition unter Berücksichtigung chirurgischer UND prothetischer Aspekte festzulegen. <br/><br/>Via CAD/CAM-Verfahren auf dieser Planung basierend angefertigte Bohrschablonen kommen intraoperativ zum Einsatz. <br/><br/>Dank Verwendung des Full-guided Systems SIMPLANT by Dentsply bedarf es nur einer einzigen Schablone vom Anfang bis zum Ende des Eingriffs!
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Vorteile
      </p>
      <br></br>
      <ul className="mt- text-custom-gray text-xs md:text-base font-normal list-disc list-inside">
  <li>Schonung gesunder Zahnhartsubstanz nach Zahnverlust</li>
  <li>minimal-invasiver, chirurgischer Eingriff</li>
  <li>Schonung benachbarter anatomischer Strukturen</li>
  <li>sichere Umsetzung geplanter Implantatpositionen</li>
  <li>Realisierung chirurgisch-prothetisch komplexer Fälle</li>
  <li>Verbesserung des Haltes von herausnehmbarem Zahnersatz</li>
</ul>
<img
  src="https://zahnarztpraxis-brueck.de/static/media/Implantat.d2fa1c699c2df9d37261.png"
  alt="Implantologie"
  className="w-[600px] mx-auto"
/>
      <a href="mailto:info@zahnarztpraxis-brueck.de">
        <button className="mt-4 text-sm md:text-base bg-custom-green text-white p-2 md:p-3 hover:bg-[#9ac764]">
          TERMIN VEREINBAREN
        </button>
      </a>
    </div>
    </div>
  )
}