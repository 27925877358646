import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Implantologie from "./pages/Implantologie";
import Zahnersatz from "./pages/Zahnersatz";
import Parodontologie from "./pages/Parodontologie.js";
import Prophylaxe from "./pages/Prophylaxe.js";
import Bleaching from "./pages/Bleaching.js";
import Fuellungstherapie from "./pages/Fuellungstherapie.js";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Datenschutz from "./pages/Datenschutz";
import Impressum from "./pages/Impressum";
function App() {
  return (
    <Router>
      <div className="max-w-[1100px] mx-auto">
        <div className="md:border-8 md:mt-10 h-full">
          <Navbar />
          <Routes>
            <Route path="/leistungen/implantologie" element={<Implantologie />} />
            <Route path="/leistungen/parodontologie" element={<Parodontologie />} />
            <Route path="/leistungen/prophylaxe" element={<Prophylaxe />} />
            <Route path="/leistungen/bleaching" element={<Bleaching />} />
            <Route path="/leistungen/zahnersatz" element={<Zahnersatz />} />
            <Route path="/leistungen/fuellungstherapie" element={<Fuellungstherapie />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
