import logo from "../img/logo.png"
import { useState, useRef, useEffect } from "react"
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const submenuRef = useRef(null)
  const timeoutRef = useRef(null)

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [])

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current)
    setIsSubmenuOpen(true)
  }

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsSubmenuOpen(false)
    }, 200) // Adjust the delay time as needed (200ms in this example)
  }

  return (
    <div className="w-100 h-10 flex m-5 md:m-10 my-8 md:my-14 justify-between">
      <a href="/">
        <img
          src={logo}
          width={148}
          height={40}
          alt="brueck"
          className="mx-auto max-w-full h-auto"
        />
      </a>

      <ul className="flex items-center text-sm md:text-base">
        <li className="mr-3 color-links">
          <a href="/">START</a>
        </li>
        <li
          className="relative group"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span
            className="color-links"
            onClick={() => {
              setIsSubmenuOpen((prev) => !prev)
            }}
          >
            LEISTUNGEN
          </span>
          <ul
            ref={submenuRef}
            className={`absolute space-y-2 mt-2 text-sm border-custom-green border px-6 py-4 right-0 ${
              isSubmenuOpen ? "block" : "hidden"
            } bg-white shadow-[5px_5px_0px_rgba(0,0,0,0.1)]`}
          >
            <li className="color-links">
    <Link to="/leistungen/implantologie">IMPLANTOLOGIE</Link>        
  </li>

  <li className="color-links">
    <Link to="/leistungen/parodontologie">PARODONTOLOGIE</Link>    
  </li>

  <li className="color-links">
    <Link to="/leistungen/zahnersatz">ZAHNERSATZ</Link>    
  </li>

  <li className="color-links">
    <Link to="/leistungen/prophylaxe">PROPHYLAXE</Link>    
  </li>

  <li className="color-links">
    <Link to="/leistungen/bleaching">BLEACHING</Link>    
  </li>

  <li className="color-links">
    <Link to="/leistungen/fuellungstherapie">FÜLLUNGSTHERAPIE</Link>    
  </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}
