import bg from "../img/bg.png"
import toothIcon from "../img/icon-tooth.png"
import clockIcon from "../img/icon-clock.png"
import phoneIcon from "../img/icon-phone.png"
import bannerImage from "../img/brueck.jpg"
import { Link } from 'react-router-dom';
export default function Home() {
  const containerStyle = {
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  }

  return (
    <>
      <div
        style={containerStyle}
        className="py-14 pb-10 md:pb-28 md:py-28 flex justify-center items-center h-[60%]"
      >
        <p className="ml-4 mt-6 md:mt-0 w-[85%] md:w-[70%] text-lg md:text-3xl text-white font-bold ">
          Wir stehen für eine fundierte weil fachkundige Behandlung Ihrer Zähne.
          Uns liegt viel daran, Ihnen den Besuch bei uns so angenehm wie möglich
          zu gestalten. Wir nehmen uns die Zeit, die Sie brauchen.
        </p>
      </div>

      <section className="m-5 md:m-10">
        <div className="flex items-center gap-2 md:gap-4">
          <img
            src={toothIcon}
            alt="icon-services"
            width={28}
            height={28}
            className="w-5 md:w-7"
          />
          <h3 className="text-lg md:text-2xl text-custom-gray font-semibold">
            LEISTUNGEN
          </h3>
        </div>
        <ul className="mt-4 md:mt-8 text-center text-base md:text-xl grid grid-cols-2 sm:flex sm:flex-wrap justify-center gap-x-8 gap-y-3 md:gap-y-6">
        <li className="color-links font-bold">
          <Link to="/leistungen/implantologie"className="border-b-2 border-transparent hover:border-custom-green">IMPLANTOLOGIE</Link>
         
          </li>

          <li className="color-links font-bold">
          <Link to="/leistungen/parodontologie"className="border-b-2 border-transparent hover:border-custom-green">PARODONTOLOGIE</Link>
         
          </li>

          <li className="color-links font-bold">
          <Link to="/leistungen/zahnersatz"className="border-b-2 border-transparent hover:border-custom-green">ZAHNERSATZ</Link>
         
          </li>
          <li className="color-links font-bold">
          <Link to="/leistungen/prophylaxe"className="border-b-2 border-transparent hover:border-custom-green">PROPHYLAXE</Link>
         
          </li>
          <li className="color-links font-bold">
          <Link to="/leistungen/bleaching"className="border-b-2 border-transparent hover:border-custom-green">BLEACHING</Link>
         
          </li>
          <li className="color-links font-bold">
          <Link to="/leistungen/fuellungstherapie"className="border-b-2 border-transparent hover:border-custom-green">FÜLLUNGSTHERAPIE</Link>
         
          </li>
        </ul>
      </section>
      <div className="flex flex-col md:flex-row gap-4 md:gap-2 m-5 md:m-10 justify-between">
        <div className="md:w-1/2 card ">
          <img
            src={clockIcon}
            alt="contact-icon"
            width={40}
            height={40}
            className="w-[10%] mx-auto"
          />
           <div className="mt-5 md:mt-8 flex flex-col gap-3">
           <p className="text-custom-gray font-semibold md:font-bold text-sm md:text-base" style={{ textAlign: 'justify' }}>
        Unsere Praxis setzt auf Flexibilität und persönlichen Service! Deshalb bieten wir Ihnen die Möglichkeit, Ihre Termine individuell nach Absprache zu vereinbaren. Sie erreichen uns telefonisch oder per E-Mail, um einen Termin zu finden, der Ihrem Zeitplan entspricht. Wir bemühen uns, Ihre Wünsche zu berücksichtigen, und freuen uns darauf, Sie zu einem für Sie passenden Zeitpunkt zu empfangen.
      </p>
    </div>
    <a href="mailto:info@zahnarztpraxis-brueck.de?subject=Terminanfrage&body=Liebes Team der Zahnarztpraxis Brück,%0D%0A%0D%0Amein Name ist [Ihr Name].%0D%0A%0D%0AIch hätte gerne einen Termin bei Ihnen in der Praxis. Am liebsten wären mir folgende Wochentage und Uhrzeiten:" className="flex justify-center">
  <button className="mt-4 text-sm md:text-base bg-custom-green text-white p-2 md:p-3 hover:bg-[#9ac764]">
    TERMIN PER MAIL VEREINBAREN
  </button>
</a>

        </div>
        <div className="md:w-1/2 card flex flex-col">
          <img
            src={phoneIcon}
            alt="contact-icon"
            width={40}
            height={40}
            className="w-[10%] mx-auto"
          />
          <div className="mt-5 md:mt-8 flex flex-col gap-3">
            <p className="text-custom-gray font-bold text-base md:text-base">
              ZAHNARZTPRAXIS BRÜCK
            </p>
            <br/>
            <p className="text-custom-gray font-semibold text-base md:text-base">
              Michaela Brück<br/>Moselstraße 6<br/>66693 Mettlach<br/><br/>
            </p>
            
            <p className="text-custom-gray font-bold text-base md:text-base">
              Tel.: 06865 276
            </p>
            <p className="text-custom-green font-semibold mt-4 text-base md:text-base">
              <a href="mailto:praxis@zahnarztpraxis-brueck.de">
                info@zahnarztpraxis-brueck.de
              </a>
            </p>
           
          </div>
          
        </div>
      </div>
      <img src={bannerImage} alt="building" />
      <section className="mt-6 md:mt-10 mx-5 md:mx-10">
        <div className="pb-5 md:pb-10">
          <h2 className="text-2xl md:text-4xl font-bold text-custom-gray">
            MICHAELA BRÜCK
          </h2>
          <h3 className="text-lg md:text-2xl text-custom-green font-semibold">
            ZAHNÄRZTIN
          </h3>
        </div>
        <div className="flex flex-col md:flex-row justify-between text-custom-gray">
          <div className="ml-4 md:w-[45%]">
            <ul className="list-disc space-y-2 text-sm md:text-base">
              <li>Staatsexamen 2000</li>
              <li>bis Ende 2003 Stabsarzt der Bundeswehr</li>
              <li>Niederlassung in eigener Praxis 2004</li>
            </ul>
            <div className="text-base md:text-xl mt-4 font-semibold text-custom-gray">
              Fortbildungen
            </div>
            <ul className="list-disc mt-2 space-y-2 text-sm md:text-base">
              <li>
                <span className="font-semibold">Prothetik</span>, Prof.
                Gutowski, München
              </li>
              <li>
                <span className="font-semibold">
                  PARODONTOLOGIE (kons.+chirurgisch)
                </span>
                , Prof. Dr. Sculean, Schweiz
              </li>
              <li>
                <span className="font-semibold">
                  interdisziplinäre Parodontologie (zertifiziert)
                </span>
                , GZM-Kongress, Karlsruhe
              </li>
              <li>
                <span className="font-semibold">
                  Silent imflammation (chronisch entzündliche Prozesse)
                </span>
                , Drs. Sudowe, Ganzimmun diagnostics
              </li>
              <li>
                <span className="font-semibold">Enzyme & Aminosäuren</span>, Dr.
                Münch, Frankfurt
              </li>
              <li className="font-semibold">
                ENZYMDIPLOM der deutschen Enzymforschungsgesellschaft
                (zertifiziert)
              </li>
              <li>
                <span className="font-semibold">
                  IMPLANTOLOGIE (zertifiziert)
                </span>
                , Akademie für zahnärztliche Fortbildung, Karlsruhe
              </li>
            </ul>
          </div>
          <div className="ml-4 md:ml-0 mt-2 md:mt-0 md:w-1/2">
            <ul className="list-disc space-y-2 text-sm md:text-base">
              <li>
                <span className="font-semibold">AUGMENTATIONsverfahren</span>,
                Dr.Dr. Stricker, Aesculap Akademie, Tuttlingen
              </li>
              <li>
                <span className="font-semibold">SIMPLANT</span>, Dr. Bornemann,
                Waltrop
              </li>
              <li>
                <span className="font-semibold">GANZHEITLICHE Zahnmedizin</span>{" "}
                (BNZ/ZAEN/GZM), Freudenstadt/München
              </li>
              <li>
                <span className="font-semibold">
                  HOMÖOPATHIE für Zahnärzte (I-IV, zertifiziert)
                </span>
                , Drs. Feldhaus/Amelunxen/Schule
              </li>
              <li>
                <span className="font-semibold">
                  MundAKUPUNKTUR nach Gleditsch (zertifiziert)
                </span>
                , Dr. Wettingfeld, Freudenstadt
              </li>
              <li>
                <span className="font-semibold">
                  Ohrakupunktur (zertifiziert)
                </span>
                , Dr. Wettingfeld, Freudenstadt
              </li>
              <li>
                <span className="font-semibold">Ernährung</span>, Dr. Graf,
                Freudenstadt
              </li>
              <li>
                <span className="font-semibold">
                  Spezialkurs Radiologie (digitale Volumentomographie)
                </span>
                , zahnärztliche Akademie, Karlsruhe
              </li>
            </ul>

            <div className="mt-4 font-semibold text-sm md:text-base">
              <p>Quo vadis?</p>
              <p className="mt-2">
                Wer aufhört, besser werden zu wollen, hört auf, gut zu sein.
              </p>
            </div>
          </div>
        </div>
      </section>
      <br />
    </>
  )
}
