import React from "react"

export default function Impressum() {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-2 m-5 md:m-10 justify-between">
    <div className="md:w-full card ">
      
      <div className="mt-5 md:mt-8 flex flex-col gap-3">
     
       
       
      
      </div>
      <p className="mt- text-custom-gray text-base md:text-base font-semibold md:font-bold">
      IMPRESSUM

      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Zahnarztpraxis <br/>
      Michaela Brück
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
        Moselstr. 6<br/>
        66693 Mettlach <br/><br/>
        Tel: 06865 - 276<br/>
        Fax: 06865 - 180025<br/>
        eMail: praxis@zahnarztpraxis-brueck.de<br/>
        web: www.zahnarztpraxis-brueck.de
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Aufsichtsbehörde:
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
        KZV Saarland Ärztekammer des Saarlandes
        Abteilung Zahnärzte
        Puccinistr. 2
        66119 Saarbrücken
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Berufsbezeichnung und verleihender Staat
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Zahnärztin, Bundesrepublik Deutschland
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Zuständige Zahnärztekammer:
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Ärztekammer des Saarlandes Abteilung Zahnärzte
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Berufsrechtliche Regelungen:
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Berufsordnung für Zahnärzte/Zahnärztinnen
Heilberufe-Kammergesetz<br/>
Zahnheilkundegesetz<br></br>
Gebührenordnung für Zahnärzte
<br></br>
abrufbar unter:
<br></br>
www.zaek-saar.de/fuer-zahnaerzte/gesetze-berufsrecht/
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Bildnachweis:
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Dentsply Sirona Implants
      </p>
      <br></br>
            <br></br>
      <a href="mailto:info@zahnarztpraxis-brueck.de">
        <button className="mt-4 text-sm md:text-base bg-custom-green text-white p-2 md:p-3 hover:bg-[#9ac764]">
          TERMIN VEREINBAREN
        </button>
      </a>
    </div>
    </div>
  )
}