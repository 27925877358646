import React from "react";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="text-right text-xs mr-5 md:mr-0 my-4 text-custom-gray">
      <span className="color-links">
        <Link to="/impressum">IMPRESSUM</Link>
      </span>
      
      <span className="mx-2"> | </span>
      <span className="color-links">
        <Link to="/datenschutz">DATENSCHUTZ</Link>
      </span>
    </div>
  )
}

export default Footer;
