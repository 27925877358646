import React from "react"

export default function Prophylaxe() {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-2 m-5 md:m-10 justify-between">
    <div className="md:w-full card ">
      
      <div className="mt-5 md:mt-8 flex flex-col gap-3">
     
       
       
      
      </div>
      <p className="mt- text-custom-gray text-base md:text-base font-semibold md:font-bold">
      PROPHYLAXE - ZAHNGESUNDHEIT ERHALTEN

      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Maßgeschneiderte Konzepte für jeden Patient
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Professionelle Zahnreinigung (PZR) 
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Als unterstützende Maßnahme zur häuslichen Zahnpflege sollte die professionelle Zahnreinigung idealerweise im halbjährlichen Turnus stattfinden, im Fall bereits bekannter Erkrankung des Zahnhalteapparates auch häufiger, Empfehlungen erfolgen individuell.<br/><br/>
Zahlreiche Versicherer haben den Nutzen bereits anerkannt und bewilligen auch eine Kostenerstattung.
      </p>

      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Individualprophylaxe
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Im Kindesalter ist die spielerische Einübung des Putzverhaltens ideal.
      <br/><br/>Da sich diese Maßnahme positiv auf die Mundgesundheit im Kindes- und Jugendalter auswirkt, sollten Eltern diese Chance unbedingt nutzen.
      <br/><br/>Wir färben die Zähne des Kindes im Sprechzimmer an, um die Beläge sichtbar zu machen. Dann instruieren wir beim Putzen, weisen auch auf Fehler bei der Ausführung hin. Durch halbjährliche Wiederholung dieses Prozesses über mehrere Jahre stellt sich seitens der Kinder eine Routine ein, von Sitzung zu Sitzung verbessern sie ihre Technik. 
      <br/><br/>Spätestens wenn die Entscheidung ansteht, ob im Jugendalter eine kieferorthopädische Behandlung stattfinden soll oder muss, zahlt sich gut eingeübte Zahnpflege aus.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Fissurenversiegelung
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Als Fissur bezeichnet man die Vertiefung, die dort entsteht, wo ein Höckerchen an ein anderes Höckerchen eines Zahnes grenzt. <br/><br/>Um zu verhindern, dass es an dieser Stelle in jungen Jahren zu Karies kommt, versiegeln wir diese. Es handelt sich dabei um eine nicht-invasive, prophylaktische Maßnahme. <br/><br/>Hierfür ätzen wir den Zahnschmelz an, um hinterher einen nur dünn gefüllten Kunststoff aufzutragen. Durch die Konsistenz des Materials "zieht sich Selbiges" schön dünn aber ausreichend stark in die Tiefe und wird anschließend lichtgehärtet (polymerisiert). <br/><br/>Im Zuge der halbjährlichen Untersuchung kontrollieren wir regelmäßig, ob der Schutz durch die applizierte Versiegelung noch gegeben ist. 
      </p>

      <br></br>
      
      <a href="mailto:info@zahnarztpraxis-brueck.de">
        <button className="mt-4 text-sm md:text-base bg-custom-green text-white p-2 md:p-3 hover:bg-[#9ac764]">
          TERMIN VEREINBAREN
        </button>
      </a>
    </div>
    </div>
  )
}