import React from "react"

export default function Datenschutz() {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-2 m-5 md:m-10 justify-between">
    <div className="md:w-full card ">
      
      <div className="mt-5 md:mt-8 flex flex-col gap-3">
     
       
       
      
      </div>
      <p className="mt- text-custom-gray text-base md:text-base font-semibold md:font-bold">
      DATENSCHUTZ

      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Datenschutzerklärung
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Verantwortlicher
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Zahnarztpraxis Michaela Brück
Moselstr. 6 <br/>
66693 Mettlach<br/>
info@zahnarztpraxis-brueck.de<br/>
Kontakt Datenschutzbeauftragter:<br/>
info@zahnarztpraxis-brueck.de<br/>
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Arten der verarbeiteten Daten:
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      - Bestandsdaten (z.B., Namen, Adressen).<br/>
- Kontaktdaten (z.B., E-Mail, Telefonnummern).<br/>
- Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).<br/>
- Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).<br/>
- Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).<br/>
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Kategorien betroffener Personen
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als „Nutzer“).
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Zweck der Verarbeitung
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.<br/>
- Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.<br/>
- Sicherheitsmaßnahmen.<br/>
- Reichweitenmessung/Marketing<br/>
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Verwendete Begrifflichkeiten
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
      <br/><br/>
„Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.
<br/><br/>
„Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.
<br/><br/>
„Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
<br/><br/>
Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
<br/><br/>
„Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Maßgebliche Rechtsgrundlagen
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Sicherheitsmaßnahmen
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten natürlicher Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
      <br></br>
Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen (Art. 25 DSGVO).
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Zusammenarbeit mit Auftragsverarbeitern und Dritten
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung erforderlich ist), Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).
      <br></br>
Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf Grundlage des Art. 28 DSGVO.
     </p>
     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Übermittlungen in Drittländer
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen (so genannte „Standardvertragsklauseln“).
     </p>

     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Rechte der betroffenen Personen
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15 DSGVO.
      <br></br>
Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
<br></br>
Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der Daten zu verlangen.
<br></br>
Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
<br></br>
Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
     </p>
     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Widerrufsrecht
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen.
     </p>
     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Widerspruchsrecht
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.
     </p>
     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Cookies und Widerspruchsrecht bei Direktwerbung
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb der Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“, werden Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein Login-Status gespeichert werden. Als „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach dem Schließen des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung oder Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von „First-Party Cookies“).

Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer Datenschutzerklärung auf.
<br></br>
Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen dieses Onlineangebotes führen.
<br></br>
Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite http://www.aboutads.info/choices/ oder die EU-Seite http://www.youronlinechoices.com/ erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden können.
     </p>
     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Löschung von Daten
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.
      <br></br>
Nach gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147 Abs. 1 AO, 257 Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handelsbücher, für Besteuerung relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4 HGB (Handelsbriefe).
<br></br>
Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung insbesondere für 7 J gemäß § 132 Abs. 1 BAO (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege, Geschäftspapiere, Aufstellung der Einnahmen und Ausgaben, etc.), für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei Unterlagen im Zusammenhang mit elektronisch erbrachten Leistungen, Telekommunikations-, Rundfunk- und Fernsehleistungen, die an Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und für die der Mini-One-Stop-Shop (MOSS) in Anspruch genommen wird.
     </p>
     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Der Gesundheitsvorsorge dienende Leistungen
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Wir verarbeiten die Daten unserer Patienten und Interessenten und anderer Auftraggeber oder Vertragspartner (einheitlich bezeichnet als „Patienten“) entsprechend Art. 6 Abs. 1 lit. b) DSGVO, um ihnen gegenüber unsere vertraglichen oder vorvertraglichen Leistungen zu erbringen. Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und die Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem zugrundeliegenden Vertragsverhältnis. Zu den verarbeiteten Daten gehören grundsätzlich Bestands- und Stammdaten der Patienten (z.B., Name, Adresse, etc.), als auch die Kontaktdaten (z.B., E-Mailadresse, Telefon, etc.), die Vertragsdaten (z.B., in Anspruch genommene Leistungen, erworbene Produkte, Kosten, Namen von Kontaktpersonen) und Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie, etc.).
      <br></br>
In Rahmen unserer Leistungen, können wir ferner besondere Kategorien von Daten gem. Art. 9 Abs. 1 DSGVO, hier insbesondere Angaben zur Gesundheit der Patienten, ggf. mit Bezug zu deren Sexualleben oder der sexuellen Orientierung, verarbeiten. Hierzu holen wir, sofern erforderlich, gem. Art. 6 Abs. 1 lit. a., Art. 7, Art. 9 Abs. 2 lit. a. DSGVO eine ausdrückliche Einwilligung der Patienten ein und verarbeiten die besonderen Kategorien von Daten ansonsten zu Zwecken der Gesundheitsvorsorge auf Grundlage des Art. 9 Abs. 2 lit h. DSGVO, § 22 Abs. 1 Nr. 1 b. BDSG.
<br></br>
Sofern für die Vertragserfüllung oder gesetzlich erforderlich, offenbaren oder übermitteln wir die Daten der Patienten im Rahmen der Kommunikation mit medizinischen Fachkräften, an der Vertragserfüllung erforderlicherweise oder typischerweise beteiligten Dritten, wie z.B. Labore, Abrechnungsstellen oder vergleichbare Dienstleister, sofern dies der Erbringung unserer Leistungen gem. Art. 6 Abs. 1 lit b. DSGVO dient, gesetzlich gem. Art. 6 Abs. 1 lit c. DSGVO vorgeschrieben ist, unseren Interessen oder denen der Patienten an einer effizienten und kostengünstigen Gesundheitsversorgung als berechtigtes Interesse gem. Art. 6 Abs. 1 lit f. DSGVO dient oder gem. Art. 6 Abs. 1 lit d. DSGVO notwendig ist. um lebenswichtige Interessen der Patienten oder einer anderen natürlichen Person zu schützen oder im Rahmen einer Einwilligung gem. Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.
<br></br>
Die Löschung der Daten erfolgt, wenn die Daten zur Erfüllung vertraglicher oder gesetzlicher Fürsorgepflichten sowie Umgang mit etwaigen Gewährleistungs- und vergleichbaren Pflichten nicht mehr erforderlich ist, wobei die Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre überprüft wird; im Übrigen gelten die gesetzlichen Aufbewahrungspflichten.
     </p>
     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Therapeutische Leistungen und Coaching
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Wir verarbeiten die Daten unserer Vertragspartner und Interessenten sowie anderer Auftraggeber, Kunden, Mandanten, Klienten oder Vertragspartner (einheitlich bezeichnet als „Vertragspartner“) entsprechend Art. 6 Abs. 1 lit. b. DSGVO, um ihnen gegenüber unsere vertraglichen oder vorvertraglichen Leistungen zu erbringen. Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und die Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem zugrundeliegenden Vertragsverhältnis.
      <br></br>
Zu den verarbeiteten Daten gehören die Stammdaten unserer Vertragspartner (z.B., Namen und Adressen), Kontaktdaten (z.B. E-Mailadressen und Telefonnummern) sowie Vertragsdaten (z.B., in Anspruch genommene Leistungen, Vertragsinhalte, vertragliche Kommunikation, Namen von Kontaktpersonen) und Zahlungsdaten (z.B., Bankverbindungen, Zahlungshistorie).
<br></br>
Besondere Kategorien personenbezogener Daten verarbeiten wir grundsätzlich nicht, außer wenn diese Bestandteile einer beauftragten oder vertragsgemäßen Verarbeitung sind.
<br></br>
Wir verarbeiten Daten, die zur Begründung und Erfüllung der vertraglichen Leistungen erforderlich sind und weisen auf die Erforderlichkeit ihrer Angabe, sofern diese für die Vertragspartner nicht evident ist, hin. Eine Offenlegung an externe Personen oder Unternehmen erfolgt nur, wenn sie im Rahmen eines Vertrags erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten, handeln wir entsprechend den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben.
<br></br>
Im Rahmen der Inanspruchnahme unserer Onlinedienste, können wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung speichern. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als auch der Interessen der Nutzer am Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche gem. Art. 6 Abs. 1 lit. f. DSGVO erforderlich oder es besteht hierzu eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c. DSGVO.
<br></br>
Die Löschung der Daten erfolgt, wenn die Daten zur Erfüllung vertraglicher oder gesetzlicher Fürsorgepflichten sowie für den Umgang mit etwaigen Gewährleistungs- und vergleichbaren Pflichten nicht mehr erforderlich sind, wobei die Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre überprüft wird; im Übrigen gelten die gesetzlichen Aufbewahrungspflichten.
     </p>

     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Vertragliche Leistungen
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Wir verarbeiten die Daten unserer Klienten und Interessenten und anderer Auftraggeber oder Vertragspartner (einheitlich bezeichnet als „Klienten“) entsprechend Art. 6 Abs. 1 lit. b) DSGVO, um ihnen gegenüber unsere vertraglichen oder vorvertraglichen Leistungen zu erbringen. Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und die Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem zugrundeliegenden Vertragsverhältnis. Zu den verarbeiteten Daten gehören grundsätzlich Bestands- und Stammdaten der Klienten (z.B., Name, Adresse, etc.), als auch die Kontaktdaten (z.B., E-Mailadresse, Telefon, etc.), die Vertragsdaten (z.B., in Anspruch genommene Leistungen, Honorare, Namen von Kontaktpersonen, etc.) und Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie, etc.).
      <br></br>
Im Rahmen unserer Leistungen, können wir ferner besondere Kategorien von Daten gem. Art. 9 Abs. 1 DSGVO, insbesondere Angaben zur Gesundheit der Klienten, ggf. mit Bezug zu deren Sexualleben oder der sexuellen Orientierung, ethnischer Herkunft oder religiösen oder weltanschaulichen Überzeugunge, verarbeiten. Hierzu holen wir, sofern erforderlich, gem. Art. 6 Abs. 1 lit. a., Art. 7, Art. 9 Abs. 2 lit. a. DSGVO eine ausdrückliche Einwilligung der Klienten ein und verarbeiten die besonderen Kategorien von Daten ansonsten zu Zwecken der Gesundheitsvorsorge auf Grundlage des Art. 9 Abs. 2 lit h. DSGVO, § 22 Abs. 1 Nr. 1 b. BDSG.
<br></br>
Sofern für die Vertragserfüllung oder gesetzlich erforderlich, offenbaren oder übermitteln wir die Daten der Klienten im Rahmen der Kommunikation mit anderen Fachkräften, an der Vertragserfüllung erforderlicherweise oder typischerweise beteiligten Dritten, wie z.B. Abrechnungsstellen oder vergleichbare Dienstleister, sofern dies der Erbringung unserer Leistungen gem. Art. 6 Abs. 1 lit b. DSGVO dient, gesetzlich gem. Art. 6 Abs. 1 lit c. DSGVO vorgeschrieben ist, unseren Interessen oder denen der Klienten an einer effizienten und kostengünstigen Gesundheitsversorgung als berechtigtes Interesse gem. Art. 6 Abs. 1 lit f. DSGVO dient oder gem. Art. 6 Abs. 1 lit d. DSGVO notwendig ist. um lebenswichtige Interessen der Klienten oder einer anderen natürlichen Person zu schützen oder im Rahmen einer Einwilligung gem. Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.
<br></br>
Die Löschung der Daten erfolgt, wenn die Daten zur Erfüllung vertraglicher oder gesetzlicher Fürsorgepflichten sowie Umgang mit etwaigen Gewährleistungs- und vergleichbaren Pflichten nicht mehr erforderlich ist, wobei die Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre überprüft wird; im Übrigen gelten die gesetzlichen Aufbewahrungspflichten.
     </p>

     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten wir dieselben Daten, die wir im Rahmen der Erbringung unserer vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden, Interessenten, Geschäftspartner und Websitebesucher betroffen. Der Zweck und unser Interesse an der Verarbeitung liegt in der Administration, Finanzbuchhaltung, Büroorganisation, Archivierung von Daten, also Aufgaben die der Aufrechterhaltung unserer Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und Erbringung unserer Leistungen dienen. Die Löschung der Daten im Hinblick auf vertragliche Leistungen und die vertragliche Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten genannten Angaben.
      <br></br>
Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung, Berater, wie z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere Gebührenstellen und Zahlungsdienstleister.
<br></br>
Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen Daten, speichern wir grundsätzlich dauerhaft.
     </p>

     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Betriebswirtschaftliche Analysen und Marktforschung
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Um unser Geschäft wirtschaftlich betreiben, Markttendenzen, Wünsche der Vertragspartner und Nutzer erkennen zu können, analysieren wir die uns vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen, etc. Wir verarbeiten dabei Bestandsdaten, Kommunikationsdaten, Vertragsdaten, Zahlungsdaten, Nutzungsdaten, Metadaten auf Grundlage des Art. 6 Abs. 1 lit. f. DSGVO, wobei zu den betroffenen Personen Vertragspartner, Interessenten, Kunden, Besucher und Nutzer unseres Onlineangebotes gehören.
      <br></br>
Die Analysen erfolgen zum Zweck betriebswirtschaftlicher Auswertungen, des Marketings und der Marktforschung. Dabei können wir die Profile der registrierten Nutzer mit Angaben, z.B. zu deren in Anspruch genommenen Leistungen, berücksichtigen. Die Analysen dienen uns zur Steigerung der Nutzerfreundlichkeit, der Optimierung unseres Angebotes und der Betriebswirtschaftlichkeit. Die Analysen dienen alleine uns und werden nicht extern offenbart, sofern es sich nicht um anonyme Analysen mit zusammengefassten Werten handelt.
<br></br>
Sofern diese Analysen oder Profile personenbezogen sind, werden sie mit Kündigung der Nutzer gelöscht oder anonymisiert, sonst nach zwei Jahren ab Vertragsschluss. Im Übrigen werden die gesamtbetriebswirtschaftlichen Analysen und allgemeine Tendenzbestimmungen nach Möglichkeit anonym erstellt.
      <br></br>
Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung, Berater, wie z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere Gebührenstellen und Zahlungsdienstleister.
<br></br>
Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen Daten, speichern wir grundsätzlich dauerhaft.
     </p>

     <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Google AdWords und Conversion-Messung
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) die Dienste der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, („Google“).
      <br></br>
Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).
<br></br>
Wir nutzen das Onlinemarketingverfahren Google "AdWords", um Anzeigen im Google-Werbe-Netzwerk zu platzieren (z.B., in Suchergebnissen, in Videos, auf Webseiten, etc.), damit sie Nutzern angezeigt werden, die ein mutmaßliches Interesse an den Anzeigen haben. Dies erlaubt uns Anzeigen für und innerhalb unseres Onlineangebotes gezielter anzuzeigen, um Nutzern nur Anzeigen zu präsentieren, die potentiell deren Interessen entsprechen. Falls einem Nutzer z.B. Anzeigen für Produkte angezeigt werden, für die er sich auf anderen Onlineangeboten interessiert hat, spricht man hierbei vom „Remarketing“. Zu diesen Zwecken wird bei Aufruf unserer und anderer Webseiten, auf denen das Google-Werbe-Netzwerk aktiv ist, unmittelbar durch Google ein Code von Google ausgeführt und es werden sog. (Re)marketing-Tags (unsichtbare Grafiken oder Code, auch als "Web Beacons" bezeichnet) in die Webseite eingebunden. Mit deren Hilfe wird auf dem Gerät der Nutzer ein individuelles Cookie, d.h. eine kleine Datei abgespeichert (statt Cookies können auch vergleichbare Technologien verwendet werden). In dieser Datei wird vermerkt, welche Webseiten der Nutzer aufgesucht, für welche Inhalte er sich interessiert und welche Angebote der Nutzer geklickt hat, ferner technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung des Onlineangebotes.
<br></br>
Ferner erhalten wir ein individuelles „Conversion-Cookie“. Die mit Hilfe des Cookies eingeholten Informationen dienen Google dazu, Conversion-Statistiken für uns zu erstellen. Wir erfahren jedoch nur die anonyme Gesamtanzahl der Nutzer, die auf unsere Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.
<br></br>
Die Daten der Nutzer werden im Rahmen des Google-Werbe-Netzwerks pseudonym verarbeitet. D.h. Google speichert und verarbeitet z.B. nicht den Namen oder E-Mailadresse der Nutzer, sondern verarbeitet die relevanten Daten cookie-bezogen innerhalb pseudonymer Nutzerprofile. D.h. aus der Sicht von Google werden die Anzeigen nicht für eine konkret identifizierte Person verwaltet und angezeigt, sondern für den Cookie-Inhaber, unabhängig davon wer dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat, die Daten ohne diese Pseudonymisierung zu verarbeiten. Die über die Nutzer gesammelten Informationen werden an Google übermittelt und auf Googles Servern in den USA gespeichert.
<br></br>
Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von Google (https://policies.google.com/technologies/ads) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google (https://adssettings.google.com/authenticated).
<br></br>
Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke
     </p>
      <br></br>
      <a href="mailto:info@zahnarztpraxis-brueck.de">
        <button className="mt-4 text-sm md:text-base bg-custom-green text-white p-2 md:p-3 hover:bg-[#9ac764]">
          TERMIN VEREINBAREN
        </button>
      </a>
    </div>
    </div>
  )
}