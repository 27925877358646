import React from "react"


export default function Zahnersatz() {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-2 m-5 md:m-10 justify-between">
    <div className="md:w-full card ">
      
      <div className="mt-5 md:mt-8 flex flex-col gap-3">
     
       
       
      
      </div>
      <p className="mt- text-custom-gray text-base md:text-base font-semibold md:font-bold">
      Zahnverlust? - Wir haben Lösungen!
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Weshalb ist es sinnvoll, wenn ein oder mehrere Zähne gezogen worden sind, eine Versorgung mit Zahnersatz anzustreben?
      </p>
         <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Bestehen Lücken, können die Zähne, die die Lücke begrenzen, z.B. in die Lücke hineinkippen; nebst Kippungen kann es auch zu Zahnwanderungen kommen, die einst stabile Okklusion (Bisslage) ist gefährdet. <br/><br/>
Zähne können sich aus ihrem Zahnfach (Alveole) herausverlängern, wenn ihnen im Gegenkiefer ein Zahn fehlt- im Extremfall kann sogar der Ausfall eines gesunden Zahnes drohen, der "nur auf dem Suche nach seinem nicht vorhandenen Partner" war.<br/><br/>
Der Zahnhalteapparat kann in Mitleidenschaft gezogen werden, weil er sich plötzlich anderen Belastungen ausgesetzt sieht, Fehl- und Überbelastungen durch die veränderte Zahnstellung können zustande kommen.<br/><br/>
Durch Überbelastung kann es zu einer Entzündung des Zahnes selbst kommen (Pulpitis).<br/><br/>
Die mögliche Folge kann die Notwendigkeit einer Wurzelkanalbehandlung sein, die ihrerseits auch nicht den Erhalt des Zahnes in jedem Falle sicherstellen kann, weil beim Abfüllen der Wurzelkanäle nur der Hauptkanal suffizient abgefüllt werden kann, von dem aber wiederum seitlich Kanäle abzweigen, die immer noch totes Material beinhalten. Nicht selten gehen deshalb solche Zähne langfristig verloren. <br/><br/>
Selbst wenn bereits Zahnverlust eingetreten ist,  werden wir Sie fachlich kompetent beraten und gemeinsam ein prothetisches Ziel anstreben, mit dem Sie langfristig wieder glücklich sein werden.
      </p>
      <br></br>

      <a href="mailto:info@zahnarztpraxis-brueck.de">
        <button className="mt-4 text-sm md:text-base bg-custom-green text-white p-2 md:p-3 hover:bg-[#9ac764]">
          TERMIN VEREINBAREN
        </button>
      </a>
    </div>
    </div>
  )
}