import React from "react"

export default function Fuellungstheraphie() {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-2 m-5 md:m-10 justify-between">
    <div className="md:w-full card ">
      
      <div className="mt-5 md:mt-8 flex flex-col gap-3">
     
       
       
      
      </div>
      <p className="mt- text-custom-gray text-base md:text-base font-semibold md:font-bold">
      FÜLLUNGSTHERAPIE - AMALGAM WAR GESTERN

      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Zahnrestauration mit biokompatiblen, hochwertigen Materialien 
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      KEIN AMALGAM! Warum?
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Amalgam ist eine Legierung, die hauptsächlich aus Silber, Zinn, Kupfer und Quecksilber besteht.
Nach Empfehlungen des Bundesinstitutes für Arzneimittel und Medizinprodukte soll der Füllungswerkstoff nicht in Schwangerschaft und Stillzeit, bei eingeschränkter Nierenfunktion und Kindern unter 12 Jahren verwendet werden.
Abwasser aus Speibecken oder Absauganlagen von Behandlungsplätzen, an denen Amalgamreste anfallen, müssen streng kontrolliert werden. Zahnärzte sind verpflichtet, sie über so genannte Amalgamabscheider zu reinigen und den daraus gewonnenen Schlamm als Sondermüll zu entsorgen.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Wie vereinbar mit Gesundheit hört sich das für Sie an?
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      1840 wurde Amalgam in den USA verboten, seit 2009 besteht in Schweden, Norwegen und Dänemark ein eben solches Verbot.
Wegen der negativen gesundheitlichen Auswirkungen verwenden wir KEIN AMALGAM in der Praxis.
Labortests zeigen, dass der Speichel von Amalgamträgern mehr Quecksilber beinhaltet als ein Mikrogramm pro Liter, das die Trinkwasserverordnung toleriert.
Ihre Atemluft enthält nach dem Essen mehr Quecksilber als der Ausstoß eines Kohlekraftwerkes. Das liegt auch daran, dass Quecksilber sich in Flüssigkeiten leicht löst und bereits bei Raumtemperatur verdampft. Besonders Quecksilberdämpfe sind gefährlich, weil sie ungehindert über die Lunge in den Körper und das zentrale Nervensystem gelangen können. 
Die Belastung durch Amalgam ist am höchsten, wenn Füllungen neu gelegt oder entfernt werden.
Im Zuge einer Sanierung mit anschließender Entgiftung ist die Entfernung allerdings unverzichtbar.
Mittels einer speziellen Vorgehensweise in Verbindung mit einem eigens dafür in Skandinavien entwickelten Absaugsystem reduzieren wir Ihre Belastung um ein Vielfaches im Vergleich zum "herkömmlichen, zahnärztlichen Herausbohren" einer Füllung. 
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Wie viel ist zu viel?
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Diese Frage läßt sich nicht pauschal beantworten.
Letztlich machen es die Summe einzelner Belastungen sowie die Konstitution des Einzelnen aus, wann der Körper außer Balance gerät und krank wird.
Amalgam ist ein wahres Chamäleon, weil es viele verschiedene Symptome provozieren kann;es als Verursacher zu enttarnen ist äußerst schwierig, die generell schädliche Wirkung wohl unumstritten.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Komposit - hochanteilig in Keramik/Quarz/Glas
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Wir verwenden ausgereifte, hochwertige Komposite der neuesten Generation.
Durch die korrekte Applikation des Materials haben Sie viel Freude an diesen hoch ästhetischen, langlebigen Füllungen.
Nach einem Anätzprozess wird der Zahn nacheinander mit dreierlei Flüssigkeiten vorbehandelt, um einen echten chemischen Verbund mit dem Material eingehen zu können, das aufwendig schichtweise eingebracht und ausgehärtet wird, um die Kräfte der Schrumpfung möglichst gering zu halten.
Abschließend wird die Oberfläche in weiteren Schritten poliert, bis sie ganz glatt ist. Dies gibt nicht nur dem Patienten ein angenehmes Gefühl sondern verhindert auch, dass Bakterien sich an Oberflächenrauhigkeiten bevorzugt ansiedeln.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Keramik
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      zahnfarben, ästhetisch und einer der hochwertigsten und verträglichsten Werkstoffe überhaupt.
Wir greifen gerne auf dieses Material zurück - bei Inlays, Onlays, Teilkronen, Kronen, Brücken und Veneers.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Hochgoldhaltige Legierungen
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Auch hier gibt es wunderbare, biokompatibele Werkstoffe, die sehr langlebig sind.
Aufgrund des stetig steigenden Goldpreises sowie der Favorisierung zahnfarbener Restaurationsmaterialien wurden sie jedoch ein wenig in den Hintergrund gedrängt. 
Unter den Gusslegierungen ist den hochgoldhaltigen Vertretern deshalb der Vorzug zu geben, weil sie am wenigsten reaktionsfreudig sind und deswegen biokompatibler.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Zemente
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Ohne sie wäre die Zahnheilkunde nahezu undenkbar.
Ihre Einsatzgebiete sind vielfältig: von der Nutzung als Unterfüllung über die Aufbaufüllung bis hin zur Befestigung von Zahnersatz.
In kaudruckbelasteten Bereichen sind sie als Füllungswerkstoff eher ungeeignet.
Sie waschen sich im Laufe der Zeit aus, sodass von keiner sehr hohen Lebenserwartung ausgegangen werden kann.
      </p>
      <br></br>
      <a href="mailto:info@zahnarztpraxis-brueck.de">
        <button className="mt-4 text-sm md:text-base bg-custom-green text-white p-2 md:p-3 hover:bg-[#9ac764]">
          TERMIN VEREINBAREN
        </button>
      </a>
    </div>
    </div>
  )
}