import React from "react"

export default function Bleaching() {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-2 m-5 md:m-10 justify-between">
    <div className="md:w-full card ">
      
      <div className="mt-5 md:mt-8 flex flex-col gap-3">
     
       
       
      
      </div>
      <p className="mt- text-custom-gray text-base md:text-base font-semibold md:font-bold">
      BLEACHING - WEISSER ALS PERLWEIS

      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Der Traum vom strahlenden Weiß - wir realisieren ihn!
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      In nur 60 Minuten hellen wir Ihre Zahnfarbe um bis zu 7 Farbstufen mit ZOOM! (USA) auf.
      <br/><br/>Während Sie sich entspannt zurücklehnen und auf das Resultat gespannt sein dürfen, bestimmen wir zunächst Ihre Ausgangszahnfarbe, bedecken Ihr Zahnfleisch mit einem speziellen Schutz, um dann den Wirkstoff aufzutragen und einzuarbeiten. Den Rest überlassen wir unserer ZOOM-Lampe. 3-4 Intervalle à 15 Minuten sind nötig, um die Zähne aufzuhellen. Dann ist die Behandlung abgeschlossen.Es bedarf keiner weiteren Maßnahmen.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Weshalb geben wir ZOOM! den Vorzug?
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Das System ist sicher, die Ergebnisse sprechen für sich, die Patienten sind begeistert.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Gefahrenpotential häuslicher Versuche - von Risiken und Nebenwirkungen

      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Leider hält die Werbung nicht, was sie verspricht-
      <br/><br/>Vorsicht geboten ist daher bei der Anwendung von Zahnweißcremes, die mit abrasiven Putzkörpern versprechen Verfärbungen zu beseitigen und die Zahnfarbe aufzuhellen. Sie sind in der Lage, Schmelz zu abradieren und die darunter befindliche Zahnhartsubstanz (Dentin), zum Vorschein zu bringen, die aber dunkler und gelblicher ist. Ein solcher Schaden ist irreversibel.
      <br/><br/>Von Homebleachingsets sollte Abstand genommen werden, da es bei konfektionierter Ware zum Verschlucken der Chemikalien und dadurch zu Verätzungen der Speiseröhre kommen kann.

      </p>
      <br></br>


      <a href="mailto:info@zahnarztpraxis-brueck.de">
        <button className="mt-4 text-sm md:text-base bg-custom-green text-white p-2 md:p-3 hover:bg-[#9ac764]">
          TERMIN VEREINBAREN
        </button>
      </a>
    </div>
    </div>
  )
}