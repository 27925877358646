import React from "react"

export default function Parodontologie() {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-2 m-5 md:m-10 justify-between">
    <div className="md:w-full card ">
      
      <div className="mt-5 md:mt-8 flex flex-col gap-3">
     
       
       
      
      </div>
      <p className="mt- text-custom-gray text-base md:text-base font-semibold md:font-bold">
      PARODONTOLOGIE - MEIN TÄTIGKEITSSCHWERPUNKT
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Zahnfleischbluten? Parodontitis – eine ernst zu nehmende Gefahr – nicht nur für die Zähne
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Alles fängt in der Regel scheinbar harmlos mit Zahnfleischbluten an…
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Rechtzeitig behandelt hat der Patient eine gute Aussicht darauf, dass der entzündliche Prozess noch umkehrbar ist. Solange das noch der Fall ist, sprechen wir von einer Gingivitis. <br/><br/>Oft aber besteht die Erkrankung schon länger, hat bereits auf den Knochen übergegriffen und ihn in Mitleidenschaft gezogen. Zahnfleisch und Knochen sind entzündet, letzterer weist meist schon deutliche Defekte durch Abbau auf. <br/><br/>Der Schaden ist irrerversibel, es liegt eine Parodontitis vor- der gesamte Zahnhalteapparat ist erkrankt. Nebst dem Risiko durch Lockerung der Zähne deren Verlust zu erleiden, wirkt sich diese Erkrankung nicht nur lokal sondern vielmehr auf den gesamten Organismus aus. Durch die Entzündung in den Zahnfleischtaschen dringen permanent Bakterien und entzündungsfördernde Botenstoffe in die Blutbahn, resultieren können Arteriosklerose, Herzinfarkt, Herzklappenschäden, Schlaganfälle, uvm. Diabetes kann begünstigt werden und umgekehrt hat ein schlecht eingestellter Diabetiker ein höheres Risiko eine Parodontitis zu entwickeln.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Screening
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Wir sondieren das Zahnfleisch regelmäßig, um evtl. bestehenden Behandlungsbedarf frühzeitig zu erkennen, den Patienten der Therapie zuzuführen und das Behandlungsergebnis stabil zu halten. <br/><br/>Als optimale Nachsorge wird der Patient in ein Recall eingebunden: regelmäßig wird er benachrichtigt, wenn eine Nachuntersuchung angezeigt oder aber eine professionelle Zahnreinigung stattfinden sollte.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Behandlung
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Wir nehmen diese in der Regel schonend mit Handinstrumenten aber auch durch Ultraschall unterstützt vor. Vor allem in therapieresistenten Fällen empfiehlt es sich herauszufinden, mit welchen Bakterienarten man es zu tun hat. Dies ermöglicht eine mikrobiologische Untersuchung in einem spezialisierten oralpathologischen Labor (Drs. Hauss, Kiel).
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Ursachen
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Verantwortlich für die meist chronische Form von Entzündung sind Bakterien. Individuelle Faktoren (Allgemeinerkankungen, Rauchen) spielen ebenfalls eine Rolle. Harte (Zahnstein) und weiche Beläge stellen Faktoren dar, die das Zahnfleisch reizen. <br/><br/>Werden diese nicht regelmäßig entfernt, kommt es zu Entzündungen. Schwillt das entzündete Zahnfleisch an, so entfernt sich der am Zahnhals anliegende Weichgewebssaum langsam aber sich von der ursprünglichen Stelle, ungehindert können Bakterien in die Tiefe vordringen. Das Immunsystem versucht natürlich, gegen die Entzündung anzukämpfen, im Zuge dessen wird aber  Knochen zerstört.
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-semibold md:font-bold">
      Schlüssel zum Erfolg
      </p>
      <br></br>
      <p className="mt- text-custom-gray text-xs md:text-base font-normal">
      Präzise Diagnostik, auf deren Basis fußende therapeutische Strategie, komplettiert durch engmaschige Kontrollen und gegebenenfalls indizierte Nachbehandlungen.
      </p>
      <br></br>
      
      <a href="mailto:info@zahnarztpraxis-brueck.de">
        <button className="mt-4 text-sm md:text-base bg-custom-green text-white p-2 md:p-3 hover:bg-[#9ac764]">
          TERMIN VEREINBAREN
        </button>
      </a>
    </div>
    </div>
  )
}